import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@common/ui-sales-components';
import { StepCompletionNavigationGuard } from '@shared/data-access-navigation';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: '.html',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  {
    path: 'quote',
    loadChildren: () =>
      import('@ao/feature-quote').then((module) => module.AoFeatureQuoteModule),
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: 'quote.html',
    redirectTo: '/quote',
    pathMatch: 'full',
  },
  {
    path: 'products',
    loadChildren: () =>
      import('@ao/feature-appliance-details').then(
        (module) => module.AoFeatureApplianceDetailsModule
      ),
  },
  // Redirect '.html' extension so the page can be loaded in Magnolia's editor:
  {
    path: 'products.html',
    redirectTo: '/products',
    pathMatch: 'full',
  },
  {
    path: 'checkout',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@common/feature-checkout-landing').then(
            (module) => module.CommonFeatureCheckoutLandingModule
          ),
      },
      {
        path: 'personal-details',
        loadChildren: () =>
          import('@ao/feature-checkout-personal-details').then(
            (module) => module.AoFeatureCheckoutPersonalDetailsModule
          ),
      },
      {
        path: 'logged-in',
        loadChildren: () =>
          import('@common/feature-checkout-logged-in-user').then(
            (module) => module.CommonFeatureCheckoutLoggedInUserModule
          ),
      },
      {
        path: 'direct-debit-payment',
        loadChildren: () =>
          import('@ao/feature-checkout-direct-debit').then(
            (module) => module.AoFeatureCheckoutDirectDebitModule
          ),
        canActivate: [StepCompletionNavigationGuard],
      },
      {
        path: 'order-confirmation',
        loadChildren: () =>
          import('@ao/feature-order-confirmation').then(
            (module) => module.AoFeatureOrderConfirmationModule
          ),
      },
    ],
  },
  { path: 'error', data: { title: 'Error' }, component: ErrorComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AoFeatureShellRoutingModule {} // Main routes for application
