import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SelectQuotePaymentOptionsFacade } from '@common/data-access-quotes';
import { fadeInOut } from '@common/ui-sales-components';
import { BuildConfigService, LoaderService } from '@common/util-foundation';
import { BuildConfig } from '@common/util-models';
import { AuthLibFacade } from '@domgen/dgx-fe-auth';
import {
  NavigationLink,
  NavigationLinks,
} from '@domgen/dgx-fe-business-models';
import { AuthStateService } from '@shared/feature-auth';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'ao-app-root',
  templateUrl: './ao-app.component.html',
  styleUrls: ['./ao-app.component.scss'],
  animations: [fadeInOut],
})
export class AoAppComponent implements OnInit {
  title = 'sales';
  config: BuildConfig = this.buildConfigService.config;
  headerNavigationLinks: NavigationLinks = this.config.header;
  loading$!: Observable<boolean>;

  public progressSteps$:
    | Observable<{ label: string; routes: string[] }[]>
    | undefined;
  public currentRoute = '';

  footerProtectLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'protect'
  );
  footerUsefulLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'useful'
  );
  footerLegalLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'legal'
  );
  constructor(
    public readonly buildConfigService: BuildConfigService,
    private router: Router,
    private loaderService: LoaderService,
    private authFacade: AuthLibFacade,
    private selectQuotePaymentOptionsFacade: SelectQuotePaymentOptionsFacade,
    private authStateService: AuthStateService
  ) {
    router.events
      .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (e.url) {
          this.currentRoute = e.url;
        }
      });

    this.progressSteps$ = combineLatest([
      this.selectQuotePaymentOptionsFacade.paymentOptions$,
      this.authFacade.isAuthenticated$,
    ]).pipe(
      map(([paymentOptions, isAuthenticated]) => {
        // In case there are more then one payment method then it should be boiler (heating equipment)
        return this.getSteps(
          isAuthenticated,
          paymentOptions.card !== undefined &&
            paymentOptions.directDebit !== undefined
        );
      })
    );
  }

  ngOnInit(): void {
    this.loading$ = this.loaderService.loading$;
    this.authStateService.setUpdateAuthState();
  }

  private getSteps(
    isAuthenticated: boolean,
    isHeating: boolean
  ): { label: string; routes: string[] }[] {
    const yourDetails = {
      label: 'Your Details',
      routes: ['/checkout/personal-details'],
    };
    const paymentMethod = {
      label: 'Payment Method',
      routes: ['/checkout/select-payment'],
    };
    const paymentDetails = {
      label: 'Payment Details',
      routes: [
        '/checkout/direct-debit-payment',
        '/checkout/card-payment',
        '/checkout/credit-card-payment',
        '/checkout/logged-in',
      ],
    };
    const confirmation = {
      label: 'Confirmation',
      routes: ['/checkout/order-confirmation'],
    };
    return [
      ...(!isAuthenticated ? [yourDetails] : []),
      ...(isHeating ? [paymentMethod] : []),
      paymentDetails,
      confirmation,
    ];
  }
}
