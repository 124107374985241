import { NavigationRuleConfig } from '@shared/data-access-navigation';
import { environment } from '@ao/util-environment';

export const NAVIGATION_RULES: NavigationRuleConfig[] = [
  {
    target: environment.applianceDetailsPage,
    stepsToComplete: [],
  },
  {
    target: environment.quotePage,
    stepsToComplete: [environment.applianceDetailsPage],
    disableNavigationForBackButton: false,
  },
  {
    target: environment.checkoutLandingPage,
    stepsToComplete: [environment.applianceDetailsPage],
    disableNavigationForBackButton: false,
  },
  {
    target: environment.checkoutPersonalDetailsPage,
    stepsToComplete: [environment.applianceDetailsPage],
    disableNavigationForBackButton: false,
  },
  {
    target: environment.checkoutDirectDebitPage,
    stepsToComplete: [environment.applianceDetailsPage],
    disableNavigationForBackButton: true,
  },
  {
    target: environment.checkoutLoggedInUserPage,
    stepsToComplete: [environment.applianceDetailsPage],
    disableNavigationForBackButton: true,
  },
  {
    target: environment.selectPaymentPage,
    stepsToComplete: [environment.applianceDetailsPage],
    disableNavigationForBackButton: true,
  },
  {
    target: environment.checkoutCardPaymentPage,
    stepsToComplete: [environment.applianceDetailsPage],
    disableNavigationForBackButton: true,
  },
  {
    target: environment.checkoutOrderConfirmationPage,
    stepsToComplete: [environment.applianceDetailsPage],
    disableNavigationForBackButton: true,
  },
];
