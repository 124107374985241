import { GoogleTagConfig } from '@common/util-models';

export const gaTagsConfig: GoogleTagConfig = {
  checkoutSteps: {
    checkoutLanding: null,
    personalDetails: 1,
    selectPayment: null,
    paymentDetails: 2,
    quote: null,
    loggedIn: 2,
    paymentDetailsSummary: null,
  },
  dimensions: {
    brandName: 'dimension81',
    paymentType: 'dimension24',
    companyCode: null,
    applianceName: 'dimension65',
    applianceDetailsValues: 'dimension68',
    excess: null,
    reference: null,
    coverType: null,
    paymentNumber: null,
    fee: 'metric15',
    schemeCode: null,
    purchaseMonth: null,
    purchaseYear: null,
    purchasePrice: null,
    lt: null,
    ltPrice: null,
  },
  config: {
    showLegacyTags: false,
  },
  applianceDetailsLandingTags: {
    pagename: 'appliance-details',
    event: 'spa-pageview',
    genericpagename: 'appliance-details',
    category: 'sales-quote',
  },
  quoteLandingTags: {
    event: 'spa-pageview',
    pagename: 'quote-summary',
    genericpagename: 'quote-summary',
    category: 'sales-quote',
  },
  planSelection: {
    event: 'GAEvent',
    eventname: 'plan-selection',
    eventCategory: 'quote-summary',
    eventAction: 'select-plan',
  },
  excessSelection: {
    event: 'GAEvent',
    eventname: 'excess-selection',
    eventCategory: 'quote-summary',
    eventAction: 'select-excess',
  },
  checkoutPersonalDetails: {
    event: 'eec.checkout',
    eventname: 'personal-details-checkout',
    customerType: 'G',
  },
  applianceFormData: {
    event: 'GAEvent',
    eventCategory: 'appliance-details',
    pagename: 'get-protected-appliance-details',
    eventName: 'applianceDetailsType',
  },
  brandFormData: {
    event: 'GAEvent',
    eventCategory: 'appliance-details',
    eventName: 'applianceDetailsBrand',
  },
  workingOrderFormData: {
    event: 'GAEvent',
    eventCategory: 'appliance-details',
    eventName: 'applianceDetailsCondition',
  },
  purchaseDateFormData: {
    event: 'GAEvent',
    eventCategory: 'appliance-details',
    eventName: 'applianceDetailsPurchaseDate',
  },
  purchasePriceFormData: {
    event: 'GAEvent',
    eventCategory: 'appliance-details',
    eventName: 'applianceDetailsPurchaseCost',
  },
  underGuaranteeFormData: {
    event: 'GAEvent',
    eventCategory: 'appliance-details',
    eventName: 'applianceDetailsGuarantee',
  },
  guaranteeDurationFormData: {
    event: 'GAEvent',
    eventCategory: 'appliance-details',
    eventName: 'applianceDetailsGuarantee',
  },
  onApplianceFormSubmission: {
    event: 'GAEvent',
    eventCategory: 'appliance-details',
    eventName: 'getQuote',
  },
  invalidApplianceFormSubmission: {
    event: 'GAEvent',
    eventname: 'errorForm',
    eventCategory: 'Error',
  },
  personalDetailsFormData: {
    event: 'spa-pageview',
    pagename: 'checkout-personal-details',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  personalDetailsFieldCompletionData: {
    event: 'GAEvent',
    eventname: 'personal-details-checkout',
    eventCategory: 'personal-details-checkout',
    eventAction: 'completed',
  },
  personalDetailsCityCompletionData: {
    event: 'GAEvent',
    eventname: 'personal-details-checkout',
    eventCategory: 'personal-details-checkout',
    eventAction: 'completed',
  },
  marketingPreferencesData: {
    event: 'GAEvent',
    eventname: 'marketing-preference-checkout',
    eventCategory: 'personal-details-checkout',
  },
  planDocumentPreferencesData: {
    event: 'GAEvent',
    eventname: 'post-plan-docs-checkout',
    eventCategory: 'personal-details-checkout',
    eventAction: 'post-plan',
  },
  checkoutSelectPaymentCommonData: {
    event: 'eec.checkout',
    eventname: 'personal-details-complete',
  },
  onLoadCheckoutSelectPaymentData: {
    event: 'spa-pageview',
    pagename: 'payment-method',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  checkoutDirectDebitCommonData: {
    event: 'eec.checkout',
    eventname: 'payment-details-checkout',
  },
  checkoutCardCommonData: {
    event: 'eec.checkout',
    eventname: 'payment-method-complete',
  },
  paymentSelectionEvent: {
    event: 'GAEvent',
    eventname: 'payment-method-selected',
    eventCategory: 'payment-method',
    eventLabel: 'clicked',
  },
  paymentDetailsCommonData: {
    event: 'spa-pageview',
    pagename: 'payment-details',
    genericpagename: 'checkout',
    category: 'sales-quote',
  },
  paymentDetailsFormFieldCompletion: {
    event: 'GAEvent',
    eventname: 'payment-details-checkout',
    eventCategory: 'payment-details-checkout',
    eventAction: 'completed',
  },
  paymentDetailsFormBuyNowData: {
    event: 'eec.checkout',
    eventname: 'payment-details-complete',
  },
  paymentDetailsAccountHolder: {
    event: 'GAEvent',
    eventname: 'account-holder-confirm-checkout',
    eventLabel: 'account-holder',
    eventAction: 'account-holder',
  },
  paymentDetailsSummaryCommonData: {
    event: 'spa-pageview',
    pagename: 'direct-debit',
    genericpagename: 'direct-debit',
  },
  orderConfirmationTaggingData: {
    event: 'eec.purchase',
    eventname: 'purchase',
    pagename: 'order-confirmation',
    genericpagename: 'order-confirmation',
    category: 'sales-quote',
  },
  onOrderConfirmationPageLoad: {
    event: 'spa-pageview',
    pagename: 'order-confirmation',
    genericpagename: 'order-confirmation',
  },
  termsAndConditionsClickEvent: {
    event: 'GAEvent',
    eventname: 'purchase-confirmation-plans-t-and-c',
    eventCategory: 'opurchase-confirmation',
    eventAction: 'link click',
    eventLabel: 'Plan terms and conditions',
  },
  backToWebSiteEvent: {
    event: 'GAEvent',
    eventname: 'purchase-confirmation-back-to-EDF',
    eventCategory: 'purchase-confirmation',
    eventAction: 'CTA click',
    eventLabel: 'Back to EDF website',
  },
  inclusionExclusionsData: {
    event: 'GAEvent',
    eventname: 'quote-tab',
    eventCategory: 'quote-summary',
    eventAction: 'quote tab clicked',
  },
  onLoadErrorPageData: {
    pagename: 'error-page',
    event: 'spa-pageview',
    genericpagename: 'error-page',
    category: 'errors',
  },
  checkoutLandingPage: {
    event: 'spa-pageview',
    pagename: 'checkout-option',
    genericpagename: 'checkout-option',
    category: 'sales-quote',
  },
};
export const googleTagGlobalProps: Record<string, string> = {
  clientName: 'AO Sales',
};
