import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from '@ao/util-environment';
import { RestartJourneyEffects } from '@common/data-access-shared';
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  CommonFeatureCoreModule,
  META_REDUCERS,
} from '@common/feature-core';
import { CommonUiSalesComponentsModule } from '@common/ui-sales-components';
import { ENVIRONMENT } from '@common/util-foundation';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import {
  HeaderModule,
  SharedAoComponentsModule,
} from '@domgen/dgx-ui-whitelabel-components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedDataAccessNavigationModule } from '@shared/data-access-navigation';
import { AoFeatureShellRoutingModule } from './ao-feature-shell-router.module';
import { AoAppComponent } from './components/ao-app.component';
import { AuthHeadersInterceptor } from './interceptors/auth-headers.interceptor';
import { NAVIGATION_RULES } from './modules/navigation-rules.config';

const modules = [
  AoFeatureShellRoutingModule,
  CommonUiSalesComponentsModule,
  HttpClientModule,
  SharedUiComponentsCoreModule,
  HeaderModule,
];

@NgModule({
  declarations: [AoAppComponent],
  imports: [
    ...modules,
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    SharedDataAccessNavigationModule.forRoot({
      defaultRedirectUrl: environment.applianceDetailsPage,
      navigationRules: [...NAVIGATION_RULES],
    }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonFeatureCoreModule,
    EffectsModule.forRoot([RestartJourneyEffects]),
    SharedAoComponentsModule,
  ],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeadersInterceptor,
      multi: true,
    },
  ],
  exports: [...modules, AoAppComponent],
})
export class AoFeatureShellModule {
  static forRoot(
    navigationConfig: AppProvidersConfig
  ): ModuleWithProviders<AoFeatureShellModule> {
    return {
      ngModule: AoFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: navigationConfig },
      ],
    };
  }
}
